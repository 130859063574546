<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BButton,BCard} from 'bootstrap-vue'



export default {
  components: {
    BCard,
    baseform
  },

  data() {
    return {
      loading :false,
      title:'Project',
      dataurl:'/project',
      baseroute:'project',
      fields:[
        { key: 'area', label: 'Area Layanan', type: 'select2', rules:'required',dataurl:'/service-area', placeholder:'Select Area'},
        { key: 'code', label: 'Kode Project', type: 'input', rules:'required' , placeholder:'Kode Project'},
        { key: 'name', label: 'Nama', type: 'input', rules:'required' , placeholder:'Nama'},
        { key: 'io_number', label: 'Nomor IO', type: 'input', rules:'required' , placeholder:'Nomor IO'},
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'autocomplate',rules:'required' },
        { type:'map', label:'Map'},
        { key: 'leader', label: 'Pimpinan', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select Pimpinan'},
        { key: 'pic', label: 'PIC Operasional', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select PIC Operasional'},
      ]
    }
  },
  // mounted(){
  //   if(this.$route.params.id != null)
  //   this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
  //     this.model = res.data
  //   })
  // },
}
</script>
